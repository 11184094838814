<template>
  <div class="section">
    <SectionDescription :title="title" :description="description" />
    <div class="tags-content">
      <div v-if="tagTitlePrefix && tags[selectedTagIndex]?.attributes.title" class="tag-title">
        {{ `${tagTitlePrefix} ${tags[selectedTagIndex]?.attributes.title}` }}
      </div>
      <div id="tags" class="tags">
        <button
          v-for="(tag, i) of tags"
          :id="'tag' + i"
          :key="tag.id"
          class="tag"
          :class="{ hovered: selectedTagIndex === i }"
          @click="
            selectedCountry = undefined;
            selectedTagIndex = i;
            goTo('tag' + i, 'tags', 16);
          "
        >
          {{ tag.attributes.name }}
        </button>
      </div>

      <div v-if="countries" class="mosaique">
        <Card
          v-for="country of countries"
          :key="country.id"
          :label="country.attributes.label"
          :url-background-image="
            country?.attributes?.thumbnail?.data?.attributes?.url ||
            country?.attributes?.thumbnail_path
          "
          :url-link="`${country.attributes.continents?.data[0]?.attributes.slug}/${country.attributes.slug}/`"
          :width="336"
        ></Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { MvCountryTag } from '@/lib/strapi-types/MvCountryTag';
import { MvCountry } from '@/lib/strapi-types/MvCountry';
import { goTo } from '@/lib/utils';

const { description, tags } = defineProps<{
  title: string;
  description?: string;
  tags: MvCountryTag[];
  tagTitlePrefix?: string;
}>();

const selectedTagIndex = $ref(0);
const selectedCountry = $ref<MvCountry>();
const countries = computed(() => tags[selectedTagIndex]?.attributes.countries?.data);
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/metrics.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/mouse.scss';

.section {
  flex-direction: column;
  align-items: center;
  padding: 60px 0;

  @include breakpoints.mobile() {
    align-items: initial;
    width: initial;
    padding: 40px 0 10px;
  }

  .tags-content {
    flex-direction: column;
    gap: 40px;

    .tag-title {
      width: metrics.$inner-width;
      font-size: 30px;
      font-weight: 800;

      @include breakpoints.mobile() {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .tags {
      width: metrics.$inner-width;

      @include breakpoints.mobile() {
        overflow-x: scroll;
        width: initial;
        margin-bottom: -10px;
        margin-left: metrics.$mobile-inner-margin;
      }

      .tag {
        @include button.outlined();

        margin-right: 48px;
        background-color: white;

        @include breakpoints.mobile() {
          margin: 0 16px 16px 0;
        }
      }
    }

    .mosaique {
      $card-width: 336px;
      $card-height: 264px;
      $card-width-mobile: 290px;
      $card-height-mobile: 268px;

      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(2, auto);

      width: metrics.$inner-width;

      @include breakpoints.mobile() {
        overflow-x: scroll;
        display: flex;

        width: initial;
        margin: 0 metrics.$mobile-inner-margin;
        padding-bottom: 8px;
      }

      & :hover {
        cursor: pointer;
      }
    }
  }
}
</style>
